<template>
    <div>
        <Head :title="'员工更改'" :out="'ProcessTask?active=2'" />
        <div class="content">
            <h3>自定义编号：{{$route.query.defined_num}}</h3>
             <van-field
                readonly
                clickable
                label-width="120"
                name="picker"
                v-model="vehicleType"
                label="选择施工工位："
                placeholder="点击选择施工工位"
                @click="vehicleTypeShow = true"
                />
                <van-popup v-model="vehicleTypeShow" position="bottom"  :close-on-click-overlay="false">
                <van-picker
                    show-toolbar
                    :columns="vehicleTypeColumns"
                    value-key="name"
                    @confirm="onConfirm(1,$event)"
                    @cancel="vehicleTypeShow = false"
                />
            </van-popup>
             <van-field
                readonly
                clickable
                label-width="120"
                name="picker"
                v-model="Staff"
                label="参与员工："
                placeholder="点击选择参与员工"
                @click="StaffShow = true"
                />
                <van-popup v-model="StaffShow" position="bottom"  :close-on-click-overlay="false">
                <van-picker
                    show-toolbar
                    :columns="StaffData"
                    value-key="realName"
                    @confirm="onConfirm(2,$event)"
                    @cancel="StaffShow = false"
                />
            </van-popup>
            <div class="table">
                <table >
                    <tr>
                        <td>员工姓名</td>
                        <td>操作</td>
                    </tr>
                    <tr v-for="(i,index) in tableData" :key="i.id">
                        <td>{{i.realName}}</td>
                        <td @click="deletes(index,1)">删除</td>
                    </tr>
                </table>
            </div>
      
        </div>
        <div class="btn">
            <van-button type="info" @click="onSubimt" >确定</van-button>
        </div>
    </div>
</template>

<script>
import Head from '@/components/Head'
import { stationNopageList,userJson ,userList,setTeammate} from '@/api/gong'
    export default {
        components: {
            Head,
        },
        data() {
            return {
                vehicleTypeShow:false,
                StaffShow:false,
                vehicleType:"",
                vehicleTypeColumns:[],
                working_procedure_station_id:'',
                tableData:[],
                StaffData:[],
                Staff:"",
            }
        },
        mounted () {
            this.lodData();
            this.getyuangong();
            this.vehicleType = this.$route.query.working_name ? this.$route.query.working_name: ''
            this.working_procedure_station_id = this.$route.query.working_id ? this.$route.query.working_id: ''
        },

        methods: {
            async onSubimt(){
                try{
                    let obj = {
                        id:this.$route.query.id,
                        working_procedure_station_id:this.working_procedure_station_id,
                        working_procedure_station_name:this.vehicleType,
                        user_json:this.tableData
                    }
                    const {data} = await setTeammate(obj).then(res=>res)
                     this.$toast.success(data.msg)
                     this.$router.push({path:'/ProcessTask',query:{active:2}})

                }
                catch{}
                
            },
            onConfirm(num,e) {
                console.log(num,e)
                if(num == 1){
                    this.vehicleType = e.name 
           
                    this.working_procedure_station_id = e.id
                    this.vehicleTypeShow = false
                }else if(num == 2){
                    this.StaffShow = false
                    this.tableData.push(e)
                }
            },
            deletes(i,num){
                this.tableData.splice(i,1)
            },
            async lodData(){
                
                try{
                    const {data} = await stationNopageList({id:this.$route.query.id}).then(res=>res)
                    console.log(data)
                    let arr = []
                        data.data.forEach(i => {
                            let obj = {
                                id:i.id,
                                name:i.name + '--' + i.number
                            }
                            arr.push(obj)
                        });
                    this.vehicleTypeColumns = arr
                }
                catch{}
                
            },
            async getyuangong(){
                try{
                    const {data} = await userJson({id:this.$route.query.id}).then(res=>res)
                    this.tableData = data.data
                    const res = await userList({type:'bulkBreaker'}).then(res=>res)

                    this.StaffData = res.data.data
                }
                catch{}
            }

        },
    }
</script>

<style lang="scss" scoped>
.content{
    margin-top: 50px;
    padding: 20px;
    .van-field{
        padding: 0;
        margin: 10px 0;
    }
    .tit{
        display: inline-block;
        line-height: 26px;
    }
}
.table{
    padding:10px;
    margin-top: 10px;
    table,table tr th, table tr td { border:1px solid #000; }
    table { 
        width: 100%;
        text-align: center; 
        border-collapse: collapse; 
        
    }
}
.btn{
    margin-top: 50px;
    text-align: center;
    .van-button{
        width: 70%;
    }
}
</style>